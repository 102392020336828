import { Header } from "../../components/Header";
import { SlideHome } from "../../components/SlideHome";
import { Gallery } from "../../components/Gallery";

import { FaInstagram } from "react-icons/fa";
import { BsGeoAlt } from "react-icons/bs";

import InputMask from 'react-input-mask';

import MarcaBG from '../../assets/MarcaBG.jpg';
import MarcaImg from '../../assets/MarcaImg.png';
import MarcaMobile from '../../assets/MarcaMobileFooter.jpg';
import DiferenciaisBG from '../../assets/DiferenciaisBG.jpg';

import { BG, Container, MarcaContent, MarcaText, DiferencialContent, Title, Box, ContatoContent} from './styles';

export function Home(){
    return (
        <>
            <Header />
            <SlideHome />    
            <main>
                <BG style={{backgroundImage: `url(${MarcaBG})`}} className="marca" id="a-marca">
                    <Container>
                        <MarcaContent>
                            <img src={MarcaImg} alt="A Marca" />
                            <MarcaText>
                                Criada com o intuito de trazer ao homem brasileiro o que há 
                                de mais atual na moda em Paris, capital da França, a <strong>Beau Goss </strong>
                                tem em seu DNA o melhor em estilo, conforto e qualidade.
                                <br /><br />
                                Cada peça possui a sofisticação que todo homem precisa 
                                para se sentir bem, com materiais de alta qualidade e detalhes diferenciados, 
                                resultando em peças únicas.
                                <br /><br />
                                E por falar em exclusividade, <strong>Beau Goss</strong> vem de uma expressão 
                                francesa que significa “O cara”. O mais confiante, o mais bonito, 
                                o mais estiloso. Aquele que, quando chega, todos percebem. 
                                Um verdadeiro <strong>Beau Goss.</strong>
                            </MarcaText>
                           
                        </MarcaContent>
                    </Container>
                </BG>
                <BG className="mobile">
                    <img src={MarcaMobile} alt="Idealizada na França - Produiza em território brasileiro" className="mobile" />
                </BG>
               
                <BG style={{backgroundImage: `url(${DiferenciaisBG})`, height: '100vh'}} id="diferenciais">
                    <Container>
                        <DiferencialContent>
                            <Title>
                                A<br />
                                Diferença<br />
                                Está nós<br />
                                Detalhes
                            </Title>
                            <Box>
                                <p>
                                    Considerado o melhor algodão do mundo, o 
                                    Algodão Pima é uma das matérias primas das peças <strong>Beau Goss. </strong> 
                                    Sua qualidade e excelência sobressaem 
                                    em comparação com os outros tecidos, por ter 
                                    um toque macio, fios mais longos e resistentes 
                                    - o que garante muito mais conforto e durabilidade 
                                    à roupa.
                                </p>
                                <p>
                                    Assim como o Pima, o <strong>Algodão Egípcio</strong> também 
                                    é um dos melhores tecidos do mundo e faz parte 
                                    das matérias primas <strong>Beau Goss.</strong> Ele traz conforto, leveza e 
                                    durabilidade às nossas roupas.
                                </p>
                            </Box>
                        </DiferencialContent>
                    </Container>
                </BG>
                <BG className="gallery" id="produtos">
                    <Gallery />
                </BG>
                <BG className="contato" id="compre-agora">
                    <Container>
                        <ContatoContent>
                            <div>
                                <a href="https://www.instagram.com/beaugoss/" target="_blank" rel="noreferrer">
                                    <FaInstagram color="#fff" size={30} />
                                </a>
                                <p>Nos acompanhe<br />no Insta</p>
                                <BsGeoAlt color="#fff" size={30} />
                                <p>Rua Maria Curupaiti 441,<br /> Sala 15 D</p>
                            </div>
                            <div className="form">
                                <form action="#">
                                    <h1>Quer falar com a gente?</h1>
                                    <input type="text" name="nome" placeholder="Nome*" />
                                    <input type="email" name="email" placeholder="E-mail*" />
                                    <div className="divided">
                                        <InputMask mask="(99) 9999-9999" type="tel" name="telefone" placeholder="Telefone*" />
                                        <select name="estado">
                                            <option value="">Estado</option>
                                            <option value="AC">Acre</option>
                                            <option value="AL">Alagoas</option>
                                            <option value="AP">Amapá</option>
                                            <option value="AM">Amazonas</option>
                                            <option value="BA">Bahia</option>
                                            <option value="CE">Ceará</option>
                                            <option value="DF">Distrito Federal</option>
                                            <option value="ES">Espírito Santo</option>
                                            <option value="GO">Goiás</option>
                                            <option value="MA">Maranhão</option>
                                            <option value="MT">Mato Grosso</option>
                                            <option value="MS">Mato Grosso do Sul</option>
                                            <option value="MG">Minas Gerais</option>
                                            <option value="PA">Pará</option>
                                            <option value="PB">Paraíba</option>
                                            <option value="PR">Paraná</option>
                                            <option value="PE">Pernambuco</option>
                                            <option value="PI">Piauí</option>
                                            <option value="RJ">Rio de Janeiro</option>
                                            <option value="RN">Rio Grande do Norte</option>
                                            <option value="RS">Rio Grande do Sul</option>
                                            <option value="RO">Rondônia</option>
                                            <option value="RR">Roraima</option>
                                            <option value="SC">Santa Catarina</option>
                                            <option value="SP">São Paulo</option>
                                            <option value="SE">Sergipe</option>
                                            <option value="TO">Tocantins</option>
                                        </select>
                                    </div>
                                    <label htmlFor="mensagem">Mensagem*</label>
                                    <textarea name="mensagem" rows={10}></textarea>
                                    <button type="submit">Enviar</button>
                                </form>
                            </div>
                        </ContatoContent>
                    </Container>
                </BG>
                <div id="WhatsAppFixo">
                    <a id="BotaoWhatsApp" title=" Enviar Mensagem " href="https://api.whatsapp.com/send?phone=5511961561111" target="_blank" rel="noopener noreferrer">
                        <span id="TextoChamada">Dúvidas? Chama no Whats!</span>
                        <img src="https://d335luupugsy2.cloudfront.net/cms/files/52484/1527884427/%24wvb6w235tna" alt="Tirar dúvida pelo WhatsApp" />
                    </a>
                </div>
            </main>
        </>
    );
}