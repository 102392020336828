import styled from 'styled-components';
import MarcaBGMobile from '../../assets/MarcaBGMobile.jpg';

export const BG = styled.section`
    background-size: cover;
    background-position: center top; 
    background-repeat: no-repeat;
    height: 1260px;

    &.mobile {
        display: none;
        background: #073551;
        text-align: center;

        img {
            width: 100%;
        }
    }

    &.gallery {
        height: auto;
        background: var(--background);
    }

    &.contato {
        background: var(--background);
        height: auto;
        padding: 1rem 0;
    }

    @media(min-width: 1591px){ 
        height: 1360px;
    }

    @media(min-width: 1401px) and (max-width: 1590px){ 
        height: 1160px;
    }


    @media(max-width: 1400px) { 
        background-position: center center; 
        height: 100%;
    }

    @media(min-width: 992px) and (max-width: 1080px){ 
        height: 840px;

        &.gallery {
            height: 90vh !important;
            background: var(--background);
        }
    }

    @media(max-width: 991px) { 
        &.marca {
            background-image: url(${MarcaBGMobile}) !important;
            background-position: center top;
        }

        &.mobile {
            display: block;

            h1 {
                font-size: 0.8rem;
            }
        }

        &.gallery {
            height: auto;
            background: var(--background);
        }
    }
`;

export const Container = styled.div`
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 1rem;

    @media(max-width: 991px) {
        max-width: 100%;
    }
`;

export const MarcaContent = styled.div`
    display: flex;
    align-items: center;
    height: 100vh;


    img {
        width: 50%;
    }

    @media(max-width: 1400px) { 
        align-items: flex-start;
        padding-top: 2rem;
    }

    @media(max-width: 991px) {
        flex-direction: column;
        align-items: center;
        height: auto !important;

        img {
            width: 100%;
        }
    }
`;

export const MarcaText = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 400;
    color: var(--text);

    @media(max-width: 1400px) { 
        padding: 0 1rem 0 1rem;
    }

    @media(max-width: 991px) { 
        padding: 2rem 0rem;
    }

    padding: 0 1rem 0 11rem;

    strong {
        font-weight: 800;
    }
`;


export const DiferencialContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;

    @media(max-width: 991px) {
        flex-direction: column;
        align-items: end;

    }
`;

export const Title = styled.h1`
    font-family: 'Mortend';     
    font-size: 1.3rem;
    line-height: 3rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
    color: var(--text);
    position: relative;
    top: 25px;

    @media (max-width: 991px) {
        line-height: 2rem;
        width: 100%;
        top: 17px;
    }
`;

export const Box = styled.div`
    background: var(--box);
    width: 500px;

    @media(max-width: 991px) {
        width: 100%;
    }

    p {
        font-family: 'Montserrat', sans-serif;
        font-size: 1rem;
        line-height: 2rem;
        font-weight: 400;
        color: var(--text);
        padding: 1rem;

        @media (max-width: 991px) {
            padding: 1.3rem 0.2rem 1rem;
            
            &:last-child {
                padding: 0 0.2rem 1rem;
            }
        }

        strong {
            font-weight: 800;
        }
    }
`;

export const ContatoContent = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: auto;

    @media(max-width: 991px) {
        flex-direction: column;
        align-items: end;
        height: auto;
        padding: 2rem 0;
    }

    div {

        &.form {
            width: 70%;
        }
        
        &.divided {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            align-items: end;
            margin-bottom: 2rem;

            input {
                margin-bottom: 0;
            }
        }

        svg {
            margin-bottom: 0.5rem;
        }

        p {
            color: var(--text);
            font-family: 'Montserrat', sans-serif;
            font-size: 1rem;
            font-weight: 800;
            margin-bottom: 5rem;
        }

        form {
            text-align: center;

            h1 {
                color: var(--text);
                font-family: 'Montserrat', sans-serif;
                font-size: 1rem;
                font-weight: 800;
                margin-bottom: 4rem;
                text-align: left;
            }

            input {
                font-family: 'Montserrat', sans-serif;
                background: transparent;
                color: var(--text);
                border-bottom: 1px solid #fff !important;
                border: 0;
                width: 100%;
                margin-bottom: 2rem;
                font-size: 1rem;
                outline: none;

                &::-webkit-input-placeholder { /* Edge */
                    color: var(--text);
                    text-transform: uppercase;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 0.8rem;
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: var(--text);
                    text-transform: uppercase;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 0.8rem;
                }

                &::placeholder {
                    color: var(--text);
                    text-transform: uppercase;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 0.8rem;
                }
            }

            select {
                font-family: 'Montserrat', sans-serif;
                background: transparent;
                color: var(--text);
                border-bottom: 1px solid #fff !important;
                border: 0;
                outline: none;
                text-transform: uppercase;
                width: 100%;
                font-size: 0.8rem;
            }

            label {
                font-family: 'Montserrat', sans-serif;
                color: var(--text);
                margin-bottom: 0.4rem;
                text-transform: uppercase;
                font-size: 0.8rem;
                display: block;
                text-align: left;
            }

            textarea {
                font-family: 'Montserrat', sans-serif;
                background: transparent;
                color: var(--text);
                border: 1px solid #fff;
                outline: none;
                width: 100%;
                font-size: 1rem;
                padding-left: 0.2rem;
                margin-bottom: 2rem;
            }
            
            button {
                background: var(--text);
                color: var(--background);
                font-family: 'Montserrat',sans-serif;
                font-size: 1rem;
                border-radius: 50px;
                padding: 0.5rem;
                font-weight: 500;
                border: none;
                width: 10rem;

                transition: filter 0.2s;

                &:hover {
                    filter: brightness(0.9)
                }
            }
        }

        @media(max-width: 991px) {

            &.form {
                width: 100%;
            }

            &.divided {
                grid-template-columns: 1fr;

                input {
                    margin-bottom: 0rem;
                }
            }
        }
    }
`;
