import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Fade } from "react-awesome-reveal";

import slide1 from '../../assets/slide_1.jpg';
import slide2 from '../../assets/slide_2.jpg';
import slide3 from '../../assets/slide_3.jpg';

import { Slide, Filter, Title } from './styles';
import 'swiper/swiper-bundle.css';

export function SlideHome() {
    SwiperCore.use([Pagination, Autoplay]);

    return (
        <Swiper slidesPerView={1} pagination={{ clickable: true }} autoplay>
           
                <SwiperSlide>
                
                    <Slide style={{backgroundImage: `url(${slide1})`}} className="slideBG">
                        <Filter>
                            <Title><Fade direction="up">Estilo, conforto e qualidade</Fade></Title>
                        </Filter>
                    </Slide>
                </SwiperSlide>
        
                <SwiperSlide>
                    <Slide style={{backgroundImage: `url(${slide2})`}} className="slideBG">
                        <Filter>
                            <Title><Fade direction="up">O melhor da moda masculina</Fade></Title>
                        </Filter>
                    </Slide>
                </SwiperSlide>
                <SwiperSlide>
                    <Slide style={{backgroundImage: `url(${slide3})`}} className="slideBG">
                        <Filter>
                            <Title><Fade direction="up">Seja “o cara”. Seja Beau Goss</Fade></Title>
                        </Filter>
                    </Slide>
                </SwiperSlide>
            
        </Swiper>
    );
}
