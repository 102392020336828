import styled from 'styled-components';

export const GalleryDiv = styled.div`

  display: flex;
  flex-wrap: wrap;

    div {
        
        flex: 33%;
        max-width: 100%;

        @media(min-width: 992px) and (max-width: 1080px){ 
            flex: 33%;

            &.grid {
                img {
                    width: 100%;
                    height: auto !important;
                }
            }
        }

        @media(max-width: 991px) {
            flex: 100%;

            &.grid {
                img {
                    width: 100%;
                    height: auto !important;
                }
            }
        }


        &.grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            max-width: 100%;

            img {
                width: 100%;
                height: auto;
            }
        }

        img {
            width: 100%;
            display: block;

            &.gallery6 {
                height: 33.2%;
            }

            &.gallery9 {
                height: 65.9%;
            }
        }
    }


`;


