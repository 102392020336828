import styled from 'styled-components';

export const Slide = styled.div`
  height: 100vh; 

  &.slideBG {
    background-size: cover;
    background-position: center top; 
    background-repeat: no-repeat;
  }
`;

export const Filter = styled.div`
    height: 100%; 
    background: rgba(0,0,0,0.4);
`;

export const Title = styled.h1`
    color: var(--text); 
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    @media (max-width: 991px) {
      font-size: 1.2rem;
      line-height: 2.2rem;
      text-align: center;
    }
`;

