import styled from 'styled-components';

export const Container = styled.header`
  background: transparent;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;

  @media (max-width: 991px) {
    background: rgba(0, 0, 0, 1);

    &.active {
      background: none;
    }

    img {
      width: 35%;
    }
  }
`;

export const Content = styled.div`
  max-width: 1400px;
  margin: 0 auto;

  padding: 2rem 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;

    

    @media (max-width: 991px) {
      display: none;

      &.header-mobile {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0px;
        flex-direction: column;

        li {
          line-height: 3rem;
          font-size: 1.4rem;
        }
      }


      &.header-mobile.active {
        background: rgba(0, 0, 0, 1);
        left: 0;
        justify-content: start;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-top: 6rem;
        z-index: -1;
      }

    }


    li {
        padding: 0 1rem;
        text-transform: uppercase;
        font-size: 0.9rem;

        a {
            text-decoration: none;
            color: var(--text);
            padding-bottom: 0.2rem;
        }
    }
  }

  .hamburger {
    display: none;
  }

  @media (max-width: 991px) {
    .hamburger {
      display: block;
    }
  }

`;