import { GalleryDiv } from './styles';

import foto1 from '../../assets/foto1.jpg';
import foto2 from '../../assets/foto2.jpg';
import foto3 from '../../assets/foto3.jpg';
import foto4 from '../../assets/foto4.jpg';
import foto5 from '../../assets/foto5.jpg';
import foto6 from '../../assets/foto6.jpg';
import foto7 from '../../assets/foto7.jpg';
import foto8 from '../../assets/foto8.jpg';
import foto9 from '../../assets/foto9.jpg';

export function Gallery() {
    return (
        <GalleryDiv>
                <div>
                    <img src={foto1} alt="Galeria 1" />
                    <img src={foto2} alt="Galeria 2" />
                    <div className="grid">
                        <img src={foto3} alt="Galeria 3" />
                        <img src={foto4} alt="Galeria 4" />
                    </div>
                   
                </div>
                <div>
                    <img src={foto5} alt="Galeria 5" />    
                    <img src={foto6} alt="Galeria 6" className="gallery6" />   
                </div>
                <div>
                    <div className="grid">
                        <img src={foto7} alt="Galeria 7" />
                        <img src={foto8} alt="Galeria 8" />
                    </div>
                    <img src={foto9} alt="Galeria 9" className="gallery9" />
                </div>
            
        </GalleryDiv>
    );
}