import { createGlobalStyle } from 'styled-components'

import MortendRegularWoff from '../fonts/Mortend-Regular.woff';
import MortendRegularWoff2 from '../fonts/Mortend-Regular.woff2';

export const GlobalStyle = createGlobalStyle`

    @font-face {
        font-family: 'Mortend';
        src: local('Mortend'), local('Mortend'),
        url(${MortendRegularWoff}) format('woff2'),
        url(${MortendRegularWoff2}) format('woff');
        font-weight: normal;
        font-style: normal;
    }


    :root {
        --background: #000000;
        --text: #FFFFFF;
        --swiper-theme-color: #FFFFFF;
        --box: #2A3476;
    }

    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html  {
        @media(max-width: 1080px) {
            font-size: 93.75%;
        }

        @media(max-width: 720px) {
            font-size: 87.5%
        }
    }

    body {
        -webkit-font-smoothing: antialiased;
    }

    body, input, textarea, button {
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 500;
    }

    button {
        cursor: pointer;
    }

    ul {
        list-style: none;
    }

    #WhatsAppFixo {
        position: fixed;
        bottom: 0px;
        right: 10px;
        z-index: 999;
    }

    #WhatsAppFixo #TextoChamada {
        float: left;
        margin-top: 10px;
        background-color: #fff;
        color: #606060;
        padding: 15px;
        border: 1px solid #ededed;
        border-radius: 3px;
        font-family: arial;
        font-size: 14px;
        box-shadow: 0px 2px 10px #ccc;
    }

    #WhatsAppFixo a img {
        width: 80px;
        height: 80px;
        border-radius: 50px;
    }

    #TextoChamada {
        display: none;
    }

    #WhatsAppFixo:hover #TextoChamada {
        display: block;
    }


    @media (max-width: 991px){
        #WhatsAppFixo a img {
            width: 60px;
            height: 60px;
        }
    }
`;