import { useState } from 'react';
import logoImg from '../../assets/logo.svg';
import './hover.css';
import './menu.css';
import { Container, Content } from './styles';

export function Header(){
    const [isNavVisible, setIsNavVisible] = useState(false);
    
    const toggleNav = () => {
        setIsNavVisible(!isNavVisible);
    }
    return (
        <Container className={isNavVisible ? 'active' : ''}>
            <Content>
                <button type="button" onClick={toggleNav} className={isNavVisible ? "hamburger hamburger--stand is-active" : "hamburger hamburger--spin"}>
                    <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                    </span>
                </button>
                <img src={logoImg} alt="Beau Goss" />
                <ul className={isNavVisible ? 'header-mobile active' : ''}>
                    <li onClick={toggleNav}><a href="/#a-marca" className="hvr-underline-from-left">A marca</a></li>
                    <li onClick={toggleNav}><a href="/#diferenciais" className="hvr-underline-from-left">diferenciais</a></li>
                    <li onClick={toggleNav}><a href="/#produtos" className="hvr-underline-from-left">produtos</a></li>
                    <li onClick={toggleNav}><a href="/#compre-agora" className="hvr-underline-from-left">compre agora</a></li>
                </ul>
                
                
            </Content>
        </Container>
    );
}